
import { useChartActions } from '@chart';
import { useShipActions } from '@/Ship';
import { useProfileActions } from '@profile';
import { useQuoteActions } from '@quotes';


const {
    updateBar,
    getBarResource,
} = useChartActions();

const getProfileResource = useProfileActions('getProfileResource');
const {
    probability,
    definePriceScale
} = useShipActions();

const {
    getCryptoResource,
    getStockResource,
    getCommodityResource,
    getForexResource
} = useQuoteActions();

export default function(stream) {

    const bar = getBarResource();
    const profile = getProfileResource();

    const resources = {
        crypto: getCryptoResource,
        stocks: getStockResource,
        commodities: getCommodityResource,
        forex: getForexResource,
    };
    const resource = resources[stream.pair.market]();

    const quote = resource.find(stream.pair.base, stream.pair.counter);

    const pricescale = definePriceScale(stream.price);
    const multiplier = (parseFloat(profile.multipliers[quote.symbol]) || 0) / 100;

    const price = Math.floor((stream.price + (stream.price * multiplier)) * pricescale) / pricescale;
    quote.price = price;

    updateBar({
        source: 'resolveChartStream',
        symbol: `${stream.pair.base}${stream.pair.counter}`,
        price: price,
        high: Math.floor(stream.high * pricescale) / pricescale,
        low: Math.floor(stream.low * pricescale) / pricescale,
        open: Math.floor(stream.open * pricescale) / pricescale,
        volume: stream.volume,
        volume_24: stream.volume_24,
    });

    if (bar.idx.value) {
        bar.subscribe.listener(bar.model);
    }
}