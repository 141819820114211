<template>
    <currency-sprite/>

    <router-view/>
    <g-preloader-overlay v-if="STATE.IS_LOADING"/>
    <g-popup v-if="PopUp.component.value">
        <component v-bind:is="PopUp.component.value" v-bind="PopUp.payload" v-on:performed="PopUp.close()"></component>
    </g-popup>

    <live-chat-widget
        v-if="STATE.IS_LOGIN"
        v-bind:license="liveChatLicense"
        v-bind:customer-name="customerName"
        v-bind:customer-email="customerEmail"
    />

</template>

<script>

import { computed, watch } from 'vue';
import { useRouter } from 'vue-router';
import CurrencySprite from '@/Ship/components/currency-sprite';
import { PopUpManager as PopUp, STATE } from '@/Ship';
import { LiveChatWidget } from '@livechat/widget-vue';
import { useProfileResources } from '@profile';


export default {
    name: 'Tipitek-APP',
    head: {
        link: [
            {
                rel: 'icon',
                href: require('@/Ship/assets/img/tipitek-white-logo.svg')
            },
        ]
    },
    components: {
        CurrencySprite,
        LiveChatWidget,
    },

    setup() {

        const router = useRouter();
        const profile = useProfileResources('Profile');

        watch(() => STATE.IS_LOGIN, () => {
            if (!STATE.IS_LOGIN) {
                router.push({ name: 'SignIn', params: router.currentRoute.value.params });
            }
        });

        document.title = {
            'trade.tipitek.com': "Tipitek",
            'front.tipitek.ovh': "DevTipitek",
            'exnesstrader.com': "Exness Invest",
        }[location.hostname] || 'Trade Terminal';
        

        const customerName = computed(() => `${profile.first_name || ""} ${profile.last_name || ""}`);
        const customerEmail = computed(() => profile.email);

        return {
            PopUp,
            STATE,
            customerName,
            customerEmail,
            liveChatLicense: process.env.VUE_APP_LIVE_CHAT_LICENSE
        };
    },
};
</script>
<style lang="scss">
@import './assets/index';

.hide-sprite {
    height: 0;
    width: 0;
    position: absolute;
    visibility: hidden;
}
</style>
