
import { useQuoteActions } from '@quotes';
import { useShipResources, STACK, ApiClient } from '@/Ship';
import { useProfileActions } from '@profile';

const { BaseResource } = useShipResources();
const getProfileResource = useProfileActions('getProfileResource');
const quoteNormalization = useQuoteActions('quoteNormalization');

export default class Crypto extends BaseResource {

	static #instance = null;

	endpoint = '/quotes';

	section = 'Trading';
	container = 'Quotes';
	resource = 'Crypto';

	symbol = 'BTCUSDT';
	
	constructor() {
	    super();
	    if (!Crypto.#instance) {
			Crypto.#instance = this;
			Crypto.#instance.profile = getProfileResource();
		}

		return Crypto.#instance;
	}

	static getInstance() {
	    return new Crypto();
	}


	load(options = {}) {
	    this.loaded.value = false;
	    this.loading.value = true;

		const params = {
			market: 'crypto'
		};

	    const includes = options.includes || this.includes;
		if (includes) {
			params['include'] = includes.join(',');
		}

		return STACK.push(() => ApiClient.get(this.endpoint, { params })).then(response => {
			
			const payload = response.data || [];
			this.collection.value = payload.map(quote => {
				const multiplier = (parseFloat(this.profile.multipliers[quote.symbol]) || 0) / 100;
				Object.assign(quote, quoteNormalization(quote, multiplier));

				return quote;
			});
			if (payload.length) {
				this.choose(this.symbol);
			}

			this.loaded.value = true;
			this.loading.value = false;

			return payload;
		});
	}

	find(base, counter = 'USDT') {
		return this.collection.value.find(ticker => (ticker.base === base && ticker.counter === counter));
	}

	choose(symbol) {

	    const index = this.collection.value.findIndex(ticker => ticker.symbol === symbol);

	    if (index >= 0) {
	        this.idx.value = index;
	        this.symbol = symbol;
	    }
	}
}
