<script setup>

import {computed, onMounted, ref} from 'vue';
import { useAuthActions } from '@auth';
import { useProfileResources } from '@profile';
import { STATE, PopUpManager as PopUp } from '@/Ship';
import { useRouter } from 'vue-router';

const isShow = ref(false);
const router = useRouter();
const { signOut } = useAuthActions();
const languages = ref(
    process.env.VUE_APP_AVAILABLE_LOCALES.split('|')
);

const profile = useProfileResources('Profile');

onMounted(() => {
    const activeTheme = STATE.IS_LIGHT_MODE ? 'light' : 'dark';
    document.documentElement.setAttribute('theme', activeTheme);
});

const logout = () => {
    signOut().then(() => router.push({ name: 'SignIn' }));
};

const onChangeTheme =  (checked) => {
    const activeTheme = checked ? 'light' : 'dark';
    STATE.IS_LIGHT_MODE = checked;
    localStorage.setItem('theme', activeTheme);
    document.documentElement.setAttribute('theme', activeTheme);
};

const scrollToNewsfeed = () => {
    const element = document.querySelector('.newsfeed');
    if (!element) return;
    
    window.scrollTo({
      top: element.getBoundingClientRect().top ,
      behavior: "smooth"
    });
}

const logo = computed(() => {
    let template = '';
    switch (true) {
        case /tipitek/.test(location.hostname):
            template = `<img src="${require('@assets/img/tipitek-logo.svg')}" width="36" alt="LOGO"/> TIPITEK`;
            break;
        case /exness/.test(location.hostname):
            template = '<span>EXNESS</span> <span>INVEST</span>';
            break;
        default:
            template = `<img src="${require('@assets/img/tms.svg')}" width="200" alt="LOGO"/>`;
            break;
    }

    return template;
});
</script>

<template>
    <div class="topbar">
        <router-link class="logo" v-bind:to="{ name: 'Trading', params: $route.params }">
            <div v-html="logo"/>
        </router-link>
        <div class="balance">
            <label>Balance:</label>
            {{ profile.balance }}
        </div>

        <div style="flex-grow: 1"></div>

        <div class="toolbar" v-bind:class="{show: isShow}">

            <slot/>

            <g-fade-container icon="newspaper" label="Trading News" v-on:click="scrollToNewsfeed" />

            <g-fade-container icon="wallet" v-bind:label="$t('parts.finances')">
                <div class="finance-menu">
                    <router-link class="menu-item" v-bind:to="{ name: 'Wallet', params: $route.params }">{{ $t('parts.wallet') }}</router-link>
                    <label class="menu-item" v-on:click="PopUp.open(require('@withdraw/components/PopUps/WithdrawForm')).setCaption($t('wallet.withdrawal', 'Withdrawal'))">{{ $t('wallet.withdrawal', 'Withdrawal') }}</label>
                    <router-link class="menu-item" v-bind:to="{ name: 'History', params: $route.params }">{{ $t('parts.history') }}</router-link>
                </div>
            </g-fade-container>

            <g-fade-container icon="profile" v-bind:label="`${profile.first_name} ${profile.last_name}`">
                <div class="profile-menu">
                    <router-link :to="{ name: 'Profile', params: { locale: $i18n.locale } }"
                                 class="menu-item"
                                 v-bind:class="{ active: $route.name === 'Profile' }">
                        <g-symbol name="profile" width="24" height="24"/>
                        {{ $t('parts.profile') }}
                    </router-link>
                    <label class="menu-item" v-on:click="logout">
                        <g-symbol name="logout" width="24" height="24"/>
                        {{ $t('base.logout') }}
                    </label>
                    <!--
                    <hr class="separator"/>
                    <label class="menu-item theme-switcher disabled">
                        {{ $t('base.light_mode') }}
                        <g-switch v-on:update:checked="onChangeTheme($event)" v-bind:checked="STATE.IS_LIGHT_MODE"/>
                    </label>
                    -->
                </div>
            </g-fade-container>
        </div>

        <g-lang-select v-if="languages.length > 1" v-bind:lang-list="languages"/>

        <g-hamburger v-model="isShow"/>

    </div>
</template>

<style lang="scss" scoped>
.topbar {
    padding: 0 10px;
    gap: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: var(--env-width, $env-width);
    margin: 0 auto;
    //box-shadow: 0 10px 10px -12px #00000055;
    //background-color: var(--bar-bg, $bar-bg);

    .logo {
        font-size: 20px;
        font-weight: bold;
        color: var(--title-text-color, $title-text-color);

        & > div {
            gap: 8px;
            display: flex;
            align-items: center;

            &:deep(span):first-child {
                font-weight: bold;
                color: var(--warning, $warning);
            }

            &:deep(span):last-child {
                margin-left: -6px;
                font-weight: normal;
                color: var(--title-text-color, $title-text-color);
            }
        }
    }
}

.g-fade-container {
    max-width: 200px;

    &.active:deep(.icon) {
        fill: white;
        background-color: var(--primary, $primary);
    }

    &:deep(.container) {
        z-index: 20;
    }

    .finance-menu,
    .profile-menu {
        padding: 10px 5px;
        overflow: hidden;
        background-color: var(--primary, $primary);
        //border: 1px solid var(--separator-color, $separator-color);

        & > .menu-item {
            display: flex;
            align-items: center;
            gap: 10px;
            padding: 5px 10px;
            margin: 5px 0;
            cursor: pointer;
            border-radius: 5px;
            color: var(--title-text-color, $title-text-color);
            fill: var(--title-text-color, $title-text-color);

            &:hover {
                //color: var(--title-text-color, $title-text-color);
                //fill: var(--title-text-color, $title-text-color);
                background-color: var(--primary-hover, $primary-hover);
            }

            &.disabled {
                pointer-events: none;
                color: var(--disabled-color, $disabled-color);
                fill: var(--disabled-color, $disabled-color);
            }

            &.theme-switcher {
                justify-content: space-between;
            }

            .g-switch {
                &:deep(input) + span {
                    background-color: var(--primary-hover, $primary-hover) !important;
                }
            }

            .menu-item {
                &.disabled {
                    opacity: 0.6;
                    pointer-events: none;
                }
            }
        }

        & > .separator {
            height: 1px;
            border: none;
            margin: 10px 0;
            background-color: var(--separator-color, $separator-color);
        }
    }
}

@media (min-width: 641px) {
    .g-hamburger {
        display: none;
    }

    .toolbar {
        gap: 10px;
        display: flex;
        align-items: center;
    }
}

@media (max-width: 640px) {
    .g-hamburger {
        z-index: 10;
    }

    .toolbar {
        z-index: 3;
        right: 0;
        position: fixed;
        top: 0;
        background-color: var(--bar-bg, $bar-bg);
        width: 240px;
        max-width: 100%;
        height: 100%;
        border-radius: 5px;
        box-shadow: 0 5px 15px -5px #00000055;
        padding: var(--bar-height, $bar-height) 10px;
        transition: max-width 0.5s, padding 0.5s 0.1s;

        &:not(.show) {
            max-width: 0;
            padding-left: 0;
            padding-right: 0;
        }
    }

    .g-fade-container {
        margin: 5px 0 10px 0;

        &:deep(button) {
            width: 100%;
        }

        &:deep(.container) {
            position: relative;
        }
    }
}

@media (min-width: 385px) {
    .balance {
        display: none;
    }
}

@media (max-width: 384px) {
    .balance {
        padding-left: 10px;
        color: var(--title-text-color, $title-text-color);
        font-family: var(--title-font, $font-title);
        border-left: 1px solid var(--separator-color, $separator-color);

        & > label {
            display: block;
            font-size: 12px;
            color: var(--main-text-color, $main-text-color);
            font-family: var(--primary-font, $font-primary);
        }
    }
}

.connect {
    fill: var(--success, $success);
    transition: fill 0.5s ease-out 0.2s;
}

.disconnect {
    fill: var(--danger, $danger);
    transition: fill 0.5s ease-out 0.2s;
}
</style>
